"use client";

import { type FC, type ReactNode } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import HomeLoading from "@/app/loading";
import ForRentIcon from "@/components/atoms/ForRentIcon";
import ForSaleIcon from "@/components/atoms/ForSaleIcon";
import ProjectIcon from "@/components/atoms/ProjectIcon";
import WikiIcon from "@/components/atoms/WikiIcon";
import CustomizeErrorBoundary from "@/components/molecules/CustomizeErrorBoundary";
import { NavigateRouter } from "@/utils";
import Image from "@/components/atoms/Image";
const Banner = dynamic(() => import("@/components/molecules/Banner"));
const HomeLogoCloud = dynamic(() => import("@/components/organisms/HomeLogoCloud"));
const HomeMenuDescription = dynamic(() => import("@/components/organisms/HomeMenuDescription"), {
  ssr: false
});
const HomeNewsBanner = dynamic(() => import("@/components/organisms/HomeNewsBanner"));
const HomeOutstanding = dynamic(() => import("@/components/organisms/HomeOutstanding"), {
  ssr: false
});
const HomeUtilitySupport = dynamic(() => import("@/components/organisms/HomeUtilitySupport"));
const HomeNews = dynamic(() => import("@/components/organisms/HomeNews"), {
  loading: () => <HomeLoading />
});
const HomeNewsForYou = dynamic(() => import("@/components/organisms/HomeNewsForYou"), {
  loading: () => <HomeLoading />
});
const HomeListImageLocation = dynamic(() => import("@/components/organisms/HomeListImageLocation"), {
  loading: () => <HomeLoading />
});
const HomeEstateNews = dynamic(() => import("@/components/organisms/HomeEstateNews"), {
  loading: () => <HomeLoading />
});
export const Layouts: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  return <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-4">
      <div className="px-4 sm:px-0 lg:col-span-full">{children}</div>
    </div>;
};
export const TitleHome: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  return <h1 className="text-2xl font-medium text-violet-900 min-w-0 flex-1 break-words decoration-yellow-500 underline-offset-8">
      {children}
    </h1>;
};
const Home: FC = () => {
  const {
    t
  } = useTranslation();
  return <>
      <Banner />

      <div className="pb-10">
        <div className="flex flex-col space-y-4">
          <div className="py-8">
            <Layouts>
              <div className="grid gap-4 grid-cols-12">
                <div className="col-span-12 sm:col-span-9">
                  <CustomizeErrorBoundary>
                    <HomeNews />
                  </CustomizeErrorBoundary>
                </div>
                <div className="sm:col-span-3 hidden sm:block">
                  <HomeNewsBanner />
                </div>
              </div>
            </Layouts>
          </div>

          <div className="bg-slate-100">
            <div className="py-8">
              <Layouts>
                <div className="flex justify-between">
                  <TitleHome>{t("home.Real Estate For You")}</TitleHome>
                  <div className="hidden md:grid md:grid-cols-2 md:divide-x md:divide-solid md:divide-yellow-600 md:items-center">
                    <Link href={NavigateRouter.REAL_ESTATE_FOR_SALE} className="text-sm text-right mr-4 text-violet-900 hover:text-violet-700">
                      {t("home.News Buy")}
                    </Link>
                    <Link href={NavigateRouter.REAL_ESTATE_FOR_RENT} className="text-sm text-left pl-4 text-violet-900 hover:text-violet-700">
                      {t("home.News Sell")}
                    </Link>
                  </div>
                  <div className="hidden lg:flex lg:flex-row lg:items-center lg:gap-4 divide-y"></div>
                </div>
                <CustomizeErrorBoundary>
                  <HomeNewsForYou />
                </CustomizeErrorBoundary>
              </Layouts>
            </div>
          </div>

          <div className="py-8">
            <Layouts>
              <div className="flex flex-col space-y-8">
                <div>
                  <TitleHome>{t("home.Outstanding project")}</TitleHome>
                  <div className="mt-4">
                    <HomeOutstanding />
                  </div>
                </div>
                <div>
                  <Image src="https://static.vecteezy.com/system/resources/previews/020/579/350/non_2x/furniture-sale-social-media-cover-or-promotional-web-banner-template-vector.jpg" alt="banner-2" className="w-full h-[6.25rem] sm:h-[15.625rem] object-cover aspect-video" width={500} height={500} />
                </div>
                <div>
                  <TitleHome>{t("home.Real estate by location")}</TitleHome>
                  <div className="mt-4">
                    <CustomizeErrorBoundary>
                      <HomeListImageLocation />
                    </CustomizeErrorBoundary>
                  </div>
                </div>

                <div>
                  <TitleHome>{t("home.Real Estate News")}</TitleHome>
                  <div className="mt-4">
                    <CustomizeErrorBoundary>
                      <HomeEstateNews />
                    </CustomizeErrorBoundary>
                  </div>
                </div>
                <div>
                  <TitleHome>{t("home.Utility support")}</TitleHome>
                  <div className="mt-4">
                    <HomeUtilitySupport />
                  </div>
                </div>
                <div>
                  <Image src="https://www.bannerrealestategroup.com/wp-content/uploads/2024/02/Banner_Horizontal_RE_Logo_RGB.png" alt="banner-2" className="w-full h-[6.25rem] sm:h-[15.625rem] object-cover aspect-video" width={500} height={500} />
                </div>
              </div>
            </Layouts>
          </div>

          <div className="py-8 border-t border-gray-300">
            <Layouts>
              <div className="grid grid-cols-1 sm:grid-cols-4 gap-8 sm:gap-16 text-center">
                <div className="flex flex-col items-center gap-4">
                  <ForSaleIcon className="h-20 w-20" />
                  <p className="text-sm lg:text-base font-semibold text-gray-900">
                    {t("home.Real estate for sale")}
                  </p>
                  <p className="text-sm text-gray-700">
                    Bạn có thể tìm thấy ngôi nhà mơ ước hoặc cơ hội đầu tư hấp
                    dẫn thông qua lượng tin rao lớn, uy tín về các loại hình bất
                    động sản bán tại Việt Nam, bao gồm bán nhà riêng, bán nhà
                    mặt tiền, bán căn hộ chung cư, bán biệt thự, bán đất, bán
                    shophouse và các loại hình BĐS khác.
                  </p>
                </div>
                <div className="flex flex-col items-center gap-4">
                  <ForRentIcon className="h-20 w-20" />
                  <p className="text-sm lg:text-base font-semibold text-gray-900">
                    {t("home.Real estate for rent")}
                  </p>
                  <p className="text-sm text-gray-700">
                    Cập nhật thường xuyên và đầy đủ các loại hình bất động sản
                    cho thuê như: thuê phòng trọ, nhà riêng, thuê biệt thự, văn
                    phòng, kho xưởng hay thuê mặt bằng kinh doanh giúp bạn nhanh
                    chóng tìm được bất động sản ưng ý.
                  </p>
                </div>
                <div className="flex flex-col items-center gap-4">
                  <ProjectIcon className="h-20 w-20" />
                  <p className="text-sm lg:text-base font-semibold text-gray-900">
                    {t("home.Project evaluation")}
                  </p>
                  <p className="text-sm text-gray-700">
                    Các video đánh giá tổng quan dự án cung cấp góc nhìn khách
                    quan của các chuyên gia về những dự án nổi bật tại Việt Nam,
                    giúp bạn đưa ra quyết định đúng đắn cho nơi an cư lý tưởng
                    hoặc cơ hội đầu tư sinh lời.
                  </p>
                </div>
                <div className="flex flex-col items-center gap-4">
                  <WikiIcon className="h-20 w-20" />
                  <p className="text-sm lg:text-base font-semibold text-gray-900">
                    {t("home.Real Estate Wiki")}
                  </p>
                  <p className="text-sm text-gray-700">
                    Ngoài cập nhật những biến động thị trường, chúng tôi còn
                    cung cấp kiến ​​thức, kinh nghiệm về mua bán, cho thuê, đầu
                    tư, vay mua nhà, phong thủy, thiết kế nhà, mọi thông tin cần
                    thiết để dẫn lối người tìm nhà tìm thấy căn nhà mơ ước.
                  </p>
                </div>
              </div>
            </Layouts>
          </div>

          <div className="bg-slate-100">
            <div className="mx-auto max-w-7xl py-8">
              <HomeLogoCloud />
            </div>
          </div>
          <div className="py-8">
            <Layouts>
              <HomeMenuDescription />
            </Layouts>
          </div>
        </div>
      </div>
    </>;
};
export default Home;